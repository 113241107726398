import React, { useState } from "react";
import { ColorSchemeName, View, Text, TextInput, CheckBox, StyleSheet, Button, Alert, Dimensions, Image } from 'react-native';
import Footer from '../components/Footer';
import { MainButton } from '../components/customUI';
import Wrap from '../components/Wrap';
import API from '../components/API';
import { transform } from "@babel/core";

export default function Login() {
    const [isSelected, setSelection] = useState(false);
    const [mButtonOpacity, setMButtonOpacity] = useState(false);
    const [email, setEmail] = useState(true);
    const [errorEmail, setErrorEmail] = useState(true);
    const [password, setPassword] = useState(true);
    const [errorPassword, setErrorPassword] = useState(true);
    const [cookies, setCookies] = useState(false);
    const [textCookies, setTextCookies] = useState(false);
    const [authError, setAuthError] = useState(false);

    function validateEmail(email) {
        const pattern  = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return pattern.test(String(email).toLowerCase());
    }

    function checkForButton(){
        if(email != undefined && password != undefined && email.length > 3 && password.length > 3){
            setMButtonOpacity(true);
        } else {
            setMButtonOpacity(false);
        }
    }

    function onChangeText (text, to) {
        

        if (to == 'email') {
            setErrorEmail(true);
            setEmail(text)
        } else if (to == 'password') {
            setErrorPassword(true);
            setPassword(text)
        }
        
        checkForButton();
    }

    function login(){
        //console.log(API.translate());
        
        if ( validateEmail(email) ) {
            if(password.length > 3) {
                API.login(email, password).then(v => {
                    setCookies(true);
                    setTimeout(function(){
                        setTextCookies(true);
                    }, 3000);
                })
                .catch(error => {
                    setAuthError(true);
                    setErrorPassword(false);
                    setErrorEmail(false);
                })
                .finally(() => {
                });
            } else {
                setErrorPassword(false);
            }
        } else {
            setErrorEmail(false);
        }
        
    }

    const styles = StyleSheet.create({
        checkboxContainer: {
            flexDirection: "row",
            marginBottom: 20,
            opacity: 0.4
        },
        checkbox: {
            alignSelf: "center",
        },
        label: {
            color: '#005588',
            fontWeight: 'bold',

            margin: 8,
        },
    });

    const ww = Dimensions.get('window').width

  return (
    <View style={{
        flex: 1,
        backgroundColor: '#bbe2ff',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
        }}>
        <View style={{width: 20, height: 20}} />
        <View style={{
            maxWidth: ww < 640 ? '90%' : 630, 
            width: '100%',
            maxHeight: 468, 
            minHeight: 468, 
            backgroundColor: '#ffffff',
            borderRadius: 20,
            shadowColor: "#000",
            padding: 20,
            shadowOffset: {
                width: 0,
                height: 8,
            },
            shadowOpacity: 0.34,
            shadowRadius: 30.32,

            elevation: 16,
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',

            alignItems: 'center'
        }}>
            <View style={{
                display: cookies ? 'block' : 'none',
                maxWidth: ww < 640 ? '90%' : 630,
                width: '100%',
                maxHeight: 450, 
                minHeight: 450, 
            }}>
                <Text
                    style={{
                        position: 'absolute',
                        left: 20,
                        right: 20,
                        top: 20,
                        color: '#005588',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: ww < 640 ? 18 : 40,
                        lineHeight: ww < 640 ? 24 : 56,
                        opacity: textCookies ? 1 : 0
                    }}
                >“Your life will be happy and peacefull” dfdfd</Text>
                <View style={{
                    position: 'absolute', 
                    bottom: 20, width: '100%', 
                    alignItems: 'center',
                    opacity: textCookies ? 1 : 0
                    }}>
                    <MainButton 
                        style={{width: ww < 640 ? '100%' : '90%', opacity: 1 }}
                        title="Start"
                    />
                </View>
                <Image 
                style={{
                    width: ww < 640 ? '158%' : 630, 
                    height: 468,
                    left: ww < 640 ? '-29%' : 0,
                    top: '-30px',
                    position: 'absolute',
                    transform: 'scale(0.8)'

                }} 
                source={require('../assets/images/cookie.gif')} />
            </View>
            <View style={{ alignItems: 'center', width: '100%', display: cookies ? 'none' : 'flex' }}>
                <Text style={{
                fontSize: ww < 640 ? 30 : 40,
                lineHeight: ww < 640 ? 40 : 60,
                textAlign: 'center',
                color: '#005588',
                fontWeight: 'bold',
                textTransform: 'uppercase'
                }}>Welcome to PPBot</Text>

                <Text style={{
                    color: '#005588',
                    fontSize: ww < 640 ? 16 : 18,
                    textAlign: 'center',
                    marginBottom: ww < 640 ? 30 : 56,
                }}>Create good reports whole month to join corpo-pizza ;-)</Text>

                <Text style={{
                    display: authError ? 'block' : 'none',
                    color: 'red',
                    fontWeight: 'bold',
                    marginBottom: 5,
                    fontSize: ww < 640 ? 12 : 14,
                    textAlign: 'center'
                }}>Email or password is wrong</Text>

                <TextInput
                    style={{
                    borderRadius: 30,
                    height: 56,
                    borderColor: errorEmail ? 'gray' : 'red',
                    minWidth: ww < 640 ? '100%' : '90%',
                    borderWidth: 1,
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginBottom: 16,
                    fontSize: 18,
                    fontStyle: 'italic'
                    }}
                    placeholder="Email"
                    onChangeText={text => onChangeText(text, 'email')}
                />
                <TextInput
                    style={{
                    borderRadius: 30,
                    height: 56,
                    borderColor: errorPassword ? 'gray' : 'red',
                    minWidth: ww < 640 ? '100%' : '90%',
                    borderWidth: 1,
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginBottom: 16,
                    fontSize: 18,
                    fontStyle: 'italic'
                    }}
                    secureTextEntry={true}
                    onChangeText={text => onChangeText(text, 'password')}
                    placeholder="Password"
                />
                <View style={{
                        flexDirection: 'row',
                        minWidth: ww < 640 ? '100%' : '90%',
                        justifyContent: 'space-between'
                }}>
                    <View style={styles.checkboxContainer}>
                        <CheckBox
                            value={isSelected}
                            onValueChange={setSelection}
                            style={styles.checkbox}
                        />
                    
                        <Text style={styles.label}>Remember me</Text>
                    </View>
                    <View />
                    <View>
                        <Text style={styles.label}>Forgot your password?</Text>
                    </View>
                </View>
                <MainButton 
                    style={{
                        minWidth: ww < 640 ? '100%' : '90%',
                        maxWidth: ww < 640 ? '100%' : '90%', 
                        opacity: mButtonOpacity ? 1 : 0.4
                    }}
                    title="Log in"
                    onPress={() => login()}
                />
            </View>
        </View>
        <View style={{width: 20, height: 20}} />
        <Footer />
    </View>
  );
}