import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';


class StorageManager {

  static async getItem(key) {
    try {
      const value = await AsyncStorage.getItem(key)
      return value;
    } catch(e) {
      console.warn('Load error', key, e)
    }
  }

  static async setItem(key, value) {
    try {
      await AsyncStorage.setItem(key, value);
    } catch(e) {
      console.warn('Save error', key, e)
    }
  }

  static async removeItem(key) {
    try {
      await AsyncStorage.removeItem(key)
    } catch(e) {
      console.warn('Remove error', key, e)
    }
  }

  static async multiGet(keys) {
    try {
      var values = await AsyncStorage.multiGet(keys)
      return values;
    } catch(e) {
      console.warn('Load error', keys, e)
    }
  }

  static async multiSet(data) {
    try {
      await AsyncStorage.multiSet(data)
    } catch(e) {
      console.warn('Save error', data, e)
    }
  }
}

export default StorageManager;