import React from "react";
import { View, Dimensions } from 'react-native';

export default function Footer() {
    const ww = Dimensions.get('window').width
    return (
        <View style={{
            height: ww < 640 ? 40 : 116, 
            width: '100%', 
            backgroundColor: '#005588',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
        }} />
    )
}