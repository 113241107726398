const ApiURL = 'https://ppbot-dev.pp-test.com/api/';
import AsyncStorage from './Storage';


function updateToken(data) {
    /*
    this.accessToken = data['access_token'];
    this.tokenExpiresDate = data['expires_in'];
    this.refreshToken = data['refresh_token'];
    
    StorageManager.setItem('token_data', JSON.stringify(data));
    */
  }

class API {

  static login(email, password) {
    console.log('Loggin in...');
    
    var body = 'grant_type=password&email=' + encodeURIComponent(email) +
      '&password=' + encodeURIComponent(password);

    var promise = new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.onreadystatechange = (e) => {
        if (request.readyState !== 4) {
          return;
        }

        if (request.status === 200) {
          updateToken(request.response);
          resolve();
        } else {
          if (request.response != undefined && request.response['error'] != undefined) {
            console.warn(request.response['error'], request.response['error_description']);
            if (request.response['error'] === 'invalid_grant') {
              reject('Invalid credentials: ' + request.response['error_description']);
            } else {
              reject('Fail to login: ' + request.response['error_description']);
            }
          } else {
            reject('Fail to login: ' + request.status);
          }
        }
      };

      request.withCredentials = true;
      request.responseType = 'json';
      request.open('POST', ApiURL + 'token');
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      request.send(body);
    });

    return promise;
  }


  static translate() {
    console.log('Loggin in...');
    
    var body = '';

    var promise = new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.onreadystatechange = (e) => {
        if (request.readyState !== 4) {
          return;
        }

        if (request.status === 200) {
          updateToken(request.response);
          resolve();
        } else {
          if (request.response == undefined && request.response['error'] == undefined) {
            reject('Status: ' + request.status);
          }
        }
      };

      request.withCredentials = true;
      request.responseType = 'json';
      request.open('POST', ApiURL + '/translations');
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      request.send(body);
    });

    return promise;
  }

}

export default API;