import React from "react";
import { View, TouchableOpacity, Text } from 'react-native';

export const MainButton = (props) => {
    const { title = 'Enter', style = {}, textStyle = {}, onPress } = props;

    return (
        <TouchableOpacity onPress={onPress} style={[{
            backgroundColor: '#005588',
            height: 56,
            borderRadius: 30,
            width: '100%',
            alignItems: 'center',
            opacity: 0.4,
            padding: 12
        }, style]}>
            <Text style={[{
                color: 'white',
                fontSize: 22,
                fontWeight: 'bold',
                textTransform: 'uppercase'
            }, textStyle]}>{props.title}</Text>
        </TouchableOpacity>
    );
};